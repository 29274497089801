import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/sing_in',
    name: 'sing_in',
    component: () => import(/* webpackChunkName: "about" */ '../views/SignInView.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { loginRequired: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/DashboardView.vue')
  },
  {
    path: '/product/:product_id',
    name: 'product',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductDetailView.vue')
  },
  {
    path: '/product/:product_id/sale',
    name: 'sale',
    meta: { loginRequired: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductSale.vue')
  },
  {
    path: '/sale/cart',
    name: 'sale_cart',
    meta: { loginRequired: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductSaleCart.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    meta: { loginRequired: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/CartView.vue')
  },
  {
    path: '/category/:category_code',
    name: 'category',
    component: () => import(/* webpackChunkName: "about" */ '../views/CategoryDetailView.vue')
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import(/* webpackChunkName: "about" */ '../views/CategoriesListView.vue')
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: () => import(/* webpackChunkName: "about" */ '../views/CatalogView.vue')
  },
  {
    path: '/thank_you',
    name: 'thank_you',
    component: () => import(/* webpackChunkName: "about" */ '../views/Thankyou.vue')
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import(/* webpackChunkName: "about" */ '../views/MaintenanceView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      // Desplázate a la posición de anclaje (hash) si la ruta contiene un hash
      return { selector: to.hash };
    } else if (savedPosition) {
      // Si se ha guardado una posición de desplazamiento, vuelve a esa posición
      return savedPosition;
    } else {
      // Si no hay un hash y no se ha guardado una posición, desplázate al principio de la página
      return { left: 0, top: 0 };
    }
  },
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('login')
  if (to.meta.loginRequired && !isAuthenticated) {
    next({
      name: 'sing_in',
    })
  } else {
    next()
  }
});

export default router
